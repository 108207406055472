import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const TurnirPage = () => (
  <Layout>
    <div id="wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>
        <article
            id="turnir"
            className="turnir"
            >
            <h2 className="major">O turniru</h2>
            <p>
                Nekada entuzijastični studenti, spremni da zajedno sa svojim
                kolegama kroz raznorazne vannastavne aktivnosti menjaju svet, a
                danas kao uspešni IT stručnjaci sa istim entuzijazmom odlučuju da
                organizuju humanitarni IT turnir - “Igraj za desetku”.{' '}
            </p>
            <p>
                Održaće se 24. i 25. avgusta, na centralnim terenima Ade Ciganlije.
                Takmičari će imati priliku da se oprobaju u basketu (3+1).{' '}
            </p>
            <p>Turnir je namenjen za pomoć Stacionaru Lipovica.</p>
            <p>
                Pravo učešća imaju svi oni koji su spremni, da ostave svoje
                računare, i u svoje spretne prste umesto tastature uzmu loptu.
                Pozivamo sve vas, bez obzira na pol, koji ste spremni da uz zabavu,
                igru i druženje učinite dobro delo.{' '}
            </p>
            <p>
                Prijave za turnir će trajati do 20.avgusta ili do popunjavanja
                mesta.{' '}
            </p>
            <h3>Pravila</h3>
            <p>
                • Jedan tim se sastoji od 3 glavna i jednog rezervnog igrača <br />
                • Izmene igrača su ”leteće” <br />
                • Trojka važi 2 poena, a ostali koševi 1 poen
                <br />
                • Nakon 5 ličnih grešaka igrač mora da napusti igru <br />• Posle 5
                timskih prekršaja protivnički tim dobija slobodno bacanje <br />
                • Igrač ima pravo da drži loptu u rukama maksimalno 5 sekundi, bez
                vođenja <br />
                • Nakon šuta protivničkog tima lopta mora da se iznese van linije za
                tri poena. Ne važi u slučaju presečene lopte
                <br />
                • Tim ima pravo na 1 time-out po utakmici u maksimalnom trajanju od
                30 sekundi <br />
                • Igra traje dok se ne osvoji 21 poen, ili 16 minuta (16 minuta
                ,,prljavo’’, tj. bez stopiranja vremena, a poslednja 2 minuta se
                vreme stopira) <br />
                • Prvi dan se igraju grupe. U okviru jedne grupe igraju 4 ekipe
                svaka sa svakom i dalje prolaze po 2 ekipe iz svake grupe (na 4 koša
                istovremeno se igra) <br />
                • Drugi dan se igra eliminaciona faza <br />
                • Igra se za sva 3 mesta <br />
            </p>
        </article>
    </div>
  </Layout>
)

export default TurnirPage
